/* eslint-disable */
import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'

import MyTheme from '../utils/myTheme'

const theme = MyTheme

const useStyles = makeStyles(theme => ({}))

const Consulting = ({ data }) => {
	const classes = useStyles()
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant='h3' align='center'>
						Consulting
					</Typography>
				</Grid>

				<Grid item xs={12} sm={4}>
					<Img fluid={data.consulting.childImageSharp.fluid} />
				</Grid>
				<Grid item xs={12} sm={8}>
					<Typography variant='body1' color='textPrimary' gutterBottom>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
						unde, nostrum laboriosam nemo corrupti magnam harum cumque quo
						officia at, commodi itaque, libero numquam accusamus ex illo velit
						quae blanditiis.
						<p>
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Distinctio blanditiis sit debitis error eligendi tempora explicabo
							quas sed quasi iste illo amet dolore omnis veritatis, saepe,
							laborum nihil officiis! Eius?
						</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
							quasi qui quo error ullam dolore pariatur unde, sit temporibus,
							earum fugit dolorum ipsam accusamus explicabo. Ipsum cupiditate
							reiciendis laboriosam minima?
						</p>
					</Typography>
				</Grid>
			</Grid>
		</>
	)
}

export default Consulting

export const query = graphql`
	query {
		consulting: file(name: { eq: "cookItaly-2017" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 500) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
